var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detailed"},_vm._l((_vm.declareDetailList),function(item,index){return _c('div',{key:item.id},[_vm._m(0,true),_c('div',[_c('p',[_c('span',[_vm._v("金额")]),_c('em',{staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(item.declareAmount + '元')+" "),_c('br'),_vm._v(" "+_vm._s('大写：（' + _vm.$tableDataHandle.ToString(item.declareAmount) + '）')+" ")])])]),_c('div',[_c('p',[_c('span',[_vm._v("备注信息")]),_c('em',[_vm._v(_vm._s(item.remark || '- -'))])])]),_c('div',[_c('p',[_c('span',[_vm._v("附件")]),_vm._l((item.declareDetailAttachmentBOList),function(itm){return (itm.attachmentUrl)?_c('em',{key:itm.attachmentUrl,staticClass:"img_box",on:{"click":function($event){_vm.openFile(
                            itm.attachmentUrl,
                            itm.attachmentName.split('.')[1]
                        )}}},[(
                            itm.attachmentName.split('.')[1] == 'pdf' ||
                            itm.attachmentName.split('.')[1] == 'PDF'
                        )?_c('i',[_c('img',{attrs:{"src":require("@/img/pdfUrl.png"),"alt":""}})]):_vm._e(),(
                            itm.attachmentName.split('.')[1] == 'doc' ||
                            itm.attachmentName.split('.')[1] == 'docx'
                        )?_c('i',[_c('img',{attrs:{"src":require("@/img/word.png"),"alt":""}})]):_vm._e(),(
                            itm.attachmentName.split('.')[1] == 'jpg' ||
                            itm.attachmentName.split('.')[1] == 'jpeg' ||
                            itm.attachmentName.split('.')[1] == 'png' ||
                            itm.attachmentName.split('.')[1] == 'PNG'
                        )?_c('i',[_c('img',{attrs:{"src":itm.attachmentUrl,"alt":""}})]):_vm._e()]):_c('em',[_vm._v("- -")])})],2)])])}),0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',[_c('span',[_vm._v("类型")]),_c('em',{staticStyle:{"display":"block"}},[_vm._v(" 1123 ")])])])}]

export { render, staticRenderFns }