<template>
    <div class="applyInvoice">
        <div
            :style="{
                background:
                    'url(' +
                    require('@/assets/bg2.png') +
                    ') top left no-repeat #ffffff',
                backgroundSize: '100% ',
            }"
            class="top_box"
        >
            <p class="top_title">
                <span>
                    <i class="iconfont icon-feiyongshenqing"></i>
                    {{ declareDetail.adminName }}的销售费用申报{{
                        declareDetail.special == 1 ? '（特批）' : ''
                    }}</span
                >
                <span>
                    <el-button
                        @click="onClose()"
                        size="mini"
                        type="primary"
                        icon="el-icon-arrow-left"
                        style="
                            background: #fff;
                            color: #2370eb;
                            border-color: #2370eb;
                            margin-right: 20px;
                        "
                        >返回列表</el-button
                    ></span
                >
            </p>
            <div class="main_box">
                <div
                    class="fixed_top"
                    :style="{ paddingBottom: isDetails ? '0' : '165px' }"
                >
                    <el-form
                        ref="form"
                        label-position="left"
                        size="small"
                        label-width="75px"
                        style="padding-top: 15px; width: 350px"
                    >
                        <el-form-item label="审批编号">
                            <div class="brand">
                                {{ declareDetail.declareNo }}
                            </div>
                        </el-form-item>
                        <el-form-item label="提交时间">
                            <div class="brand">
                                {{ declareDetail.createTime }}
                            </div>
                        </el-form-item>
                        <el-form-item label="所在部门">
                            <div class="brand">
                                {{ returnName(declareDetail) }}
                            </div>
                        </el-form-item>
                        <el-form-item label="费用类型">
                            <div class="brand">
                                {{
                                    declareDetail.declareType == 1
                                        ? '订单销售费用'
                                        : declareDetail.declareType == 2
                                        ? '月度报销费用'
                                        : '- -'
                                }}
                            </div>
                        </el-form-item>
                        <el-form-item label="客户">
                            <div class="brand">
                                {{
                                    declareDetail.customer
                                        ? declareDetail.customer.companyName
                                        : '- -' || '- -'
                                }}
                            </div>
                        </el-form-item>
                        <el-form-item label="合同">
                            <div class="brand">
                                {{
                                    declareDetail.contract
                                        ? declareDetail.contract.contractNo
                                        : '- -'
                                }}
                            </div>
                        </el-form-item>
                        <el-form-item label="销售订单">
                            <div class="brand">
                                {{
                                    declareDetail.orderBO
                                        ? declareDetail.orderBO.orderNo
                                        : '- -'
                                }}
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div
                                class="other_info"
                                v-if="
                                    declareDetail.orderBO ||
                                    declareDetail.contractTargetBO
                                "
                            >
                                <p>
                                    <span>产品类别</span>
                                    <span>{{ returnProductName() }}</span>
                                </p>
                                <p>
                                    <span>版本</span>
                                    <span>{{ returnText('version') }}</span>
                                </p>
                                <p>
                                    <span>购买类型</span>
                                    <span>{{
                                        declareDetail.orderBO
                                            ? returnText(
                                                  'productType',
                                                  'orderType'
                                              )
                                            : returnText('productType')
                                    }}</span>
                                </p>
                                <p>
                                    <span>用户数</span>
                                    <span>{{
                                        declareDetail.orderBO
                                            ? singleData.shouldNumber
                                            : singleData.number || '- -'
                                    }}</span>
                                </p>
                                <p>
                                    <span>年限</span>
                                    <span>{{
                                        declareDetail.orderBO
                                            ? singleData.shouldTerm
                                            : singleData.term || '- -'
                                    }}</span>
                                </p>
                                <p>
                                    <span>金额</span>
                                    <span>{{
                                        singleData.receivableAmount || '- -'
                                    }}</span>
                                </p>
                            </div>
                        </el-form-item>
                        <el-form-item label="申报金额">
                            <div class="brand">{{ declareDetail.amount }}</div>
                        </el-form-item>
                        <el-form-item>
                            <div
                                class="other_info"
                                v-if="
                                    declareDetail.declareType == 1 &&
                                    (declareDetail.contractTargetBO ||
                                        declareDetail.orderBO)
                                "
                            >
                                <p>
                                    <span>结算价</span>
                                    <span>{{
                                        declareDetail.settleAmount
                                    }}</span>
                                </p>
                                <p>
                                    <span>业绩系数</span>
                                    <span>{{ declareDetail.ratio }}%</span>
                                </p>
                                <p>
                                    <span>预估业绩</span>
                                    <span>{{
                                        declareDetail.predictAmount
                                    }}</span>
                                </p>
                            </div>
                        </el-form-item>

                        
                        <el-form-item label="实际支付" v-if="isDetails == 1 && declareDetail.status == 11">
                            <div class="brand">{{ declareDetail.actualPayAmount }}</div>
                        </el-form-item>
                        <el-form-item label="支付方式" v-if="isDetails == 1 && declareDetail.status == 11">
                            <div class="brand">{{ declareDetail.payStr }}</div>
                        </el-form-item>
                        <el-form-item label="支付时间" v-if="isDetails == 1 && declareDetail.status == 11">
                            <div class="brand">{{ declareDetail.payTime }}</div>
                        </el-form-item>

                    </el-form>
                    <div class="fixed_bottom" v-if="isDetails != 1">
                        <el-form
                            ref="form"
                            label-position="left"
                            size="small"
                            label-width="75px"
                            style="padding-top: 15px"
                        >
                            <div
                                style="margin-bottom: 12px; overflow: hidden"
                                v-if="
                                    declareDetail.status == 2 &&
                                    declareDetail.declareType == 1 &&
                                    declareDetail.special == 1
                                "
                            >
                                <el-form-item
                                    style="width: 50%; float: left"
                                    label="业绩系数"
                                >
                                    <el-input
                                        v-model="form.ratio"
                                        size="small"
                                        style="width: 100px"
                                        placeholder="0-100"
                                        @input="onBlurRatio"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    style="width: 50%; float: left"
                                    label="预估业绩"
                                >
                                    <div>
                                        {{
                                            parseInt(
                                                estimateAmount.achievement
                                            ) || 0
                                        }}
                                    </div>
                                </el-form-item>
                            </div>
                            <el-form-item label="审批备注">
                                <el-input
                                    v-model="form.remark"
                                    size="small"
                                    style="width: 285px"
                                    type="textarea"
                                    rows="3"
                                    placeholder="请输入审批备注"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
        <div class="bnt">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #333;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                v-if="isDetails != 1"
                round
                type="primary"
                size="mini"
                style="background: #d0021b; border-color: #d0021b; color: #fff"
                @click="onSubmit(0)"
                >驳回</el-button
            >
            <el-button
                v-if="isDetails != 1"
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit(1)"
                >同意</el-button
            >
            <el-button
                v-if="
                    isDetails == 1 &&
                    adminId == declareDetail.adminId &&
                    (declareDetail.status == 1 ||
                        declareDetail.status == 2 ||
                        declareDetail.status == 4)
                "
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onRevoke"
                >撤销</el-button
            >
        </div>
        <!-- 删除联系人 -->
        <el-dialog
            :visible.sync="dialogDelete"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    提示
                </div>
            </template>
            <div class="Delcontent">
                当前特批申请未输入新业绩系数，是否直接保存？
            </div>
            <div class="but-bottom">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="dialogDelete = false"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onSubmit(1)"
                    >确定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { declareUpdate, declareGet, declareAudit } from '@/api/cost/declare.js';
import DeclareInfo from './components/declareInfo.vue';
import Detailed from './components/detailed.vue';
import AuditRemark from './components/auditRemark.vue';
import { contracGet } from '@/api/contr/contr.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            declareDetail: {},
            auditData: {},
            form: {},
            status: '',
            userType: sessionStorage.getItem('userType'),
            adminId: sessionStorage.getItem('adminId'),
            isDetails: this.$route.query.isDetails,
            singleData: {},
            priceMap: {},
            discount: {},
            estimateAmount: {},
            dialogDelete: false,
        };
    },
    watch: {},
    components: {
        DeclareInfo,
        Detailed,
        AuditRemark,
    },
    created() {},
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            declareGet(data).then(async (res) => {
                if (res.code == 200) {
                    this.declareDetail = res.data;
                    if (res.data.contractId) {
                        let result = await this.contracGet(res.data.contractId);
                        if (result.code == 200) {
                            this.declareDetail.contract = result.data;
                        }
                    }
                    if (res.data.orderId) {
                        this.singleData = res.data.orderBO;
                        this.getAchievement(
                            res.data.orderBO.shouldNumber,
                            res.data.orderBO.shouldTerm
                        );
                    }
                    if (res.data.contractTargetBO && !res.data.orderId) {
                        this.singleData = res.data.contractTargetBO;
                        this.getAchievement(
                            res.data.contractTargetBO.number,
                            res.data.contractTargetBO.term
                        );
                    }
                }
            });
        },
        contracGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            return contracGet(data);
        },
        //   撤销
        onRevoke() {
            let data = {
                param: {
                    id: this.declareDetail.id,
                    status: 9,
                },
            };
            declareUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        getAchievement(number, term) {
            this.$Axios({
                url: `/sum?account=${number}&year=${term}`,
                method: 'get',
            }).then((res) => {
                // this.aliPriceMap = res.data.aliPriceMap;
                // this.neteasePriceMap = res.data.neteasePriceMap;
                this.priceMap = res.qqPriceMap;
                this.priceMap.total = this.priceMap.yearPrice
                    ? this.priceMap.yearPrice * term
                    : 0;
                if (number < 100) {
                    res.qqDiscountPriceList.forEach((item, index) => {
                        switch (index) {
                            case 0:
                                item.discountPrice = item.sumPrice;
                                item.discountYearsPrice = parseFloat(
                                    (item.discountPrice / 3).toFixed(2)
                                );
                                item.discountYearsNumPrice = parseFloat(
                                    (item.discountPrice / 3 / number).toFixed(2)
                                );
                                break;
                            case 1:
                                item.discountPrice = (item.sumPrice * 9) / 10;
                                item.discountYearsPrice = parseFloat(
                                    (item.discountPrice / 4).toFixed(2)
                                );
                                item.discountYearsNumPrice = parseFloat(
                                    (item.discountPrice / 4 / number).toFixed(2)
                                );
                                break;
                            case 2:
                                item.discountPrice = (item.sumPrice * 8) / 10;
                                item.discountYearsPrice = parseFloat(
                                    (item.discountPrice / 5).toFixed(2)
                                );
                                item.discountYearsNumPrice = parseFloat(
                                    (item.discountPrice / 5 / number).toFixed(2)
                                );
                                break;
                            case 3:
                                item.discountPrice = (item.sumPrice * 7) / 10;
                                item.discountYearsPrice = parseFloat(
                                    (item.discountPrice / 6).toFixed(2)
                                );
                                item.discountYearsNumPrice = parseFloat(
                                    (item.discountPrice / 6 / number).toFixed(2)
                                );
                                break;
                        }
                    });
                }

                // this.discountPriceList = res.qqDiscountPriceList;
                if (number >= 5 && number < 20) {
                    this.priceMap.bossDiscountTotal =
                        (this.priceMap.total * 50) / 100;
                    // this.jyNum = (this.priceMap.total * 65) / 100;
                } else if (number >= 20 && number < 50) {
                    this.priceMap.bossDiscountTotal =
                        (this.priceMap.total * 5) / 10;
                    // this.jyNum = (this.priceMap.total * 6) / 10;
                } else if (number >= 50 && number < 100) {
                    this.priceMap.bossDiscountTotal =
                        (this.priceMap.total * 5) / 10;
                    // this.jyNum = this.priceMap.bossDiscountTotal;
                } else {
                    if (term <= 4) {
                        if (term == 1 || term == 2) {
                            if (number >= 100 && number < 200) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 85 * 5) / 1000;
                            } else if (number >= 200 && number < 500) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 8 * 5) / 100;
                            } else if (number >= 500 && number < 1000) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 7 * 5) / 100;
                            } else {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 6 * 5) / 100;
                            }
                        } else {
                            if (number >= 100 && number < 200) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 8 * 5) / 100;
                            } else if (number >= 200 && number < 500) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 7 * 5) / 100;
                            } else if (number >= 500 && number < 1000) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 6 * 5) / 100;
                            } else {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 5 * 5) / 100;
                            }
                        }
                    } else {
                        if (term == 5) {
                            if (number >= 100 && number < 200) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 75 * 5) / 1000;
                            } else if (number >= 200 && number < 500) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 6 * 5) / 100;
                            } else if (number >= 500 && number < 1000) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 5 * 5) / 100;
                            } else {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 4 * 5) / 100;
                            }
                        } else if (term == 6) {
                            if (number >= 100 && number < 200) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 7 * 5) / 100;
                            } else if (number >= 200 && number < 500) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 55 * 5) / 1000;
                            } else if (number >= 500 && number < 1000) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 45 * 5) / 1000;
                            } else {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 35 * 5) / 1000;
                            }
                        } else if (term == 7 || term == 8) {
                            if (number >= 100 && number < 200) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 65 * 5) / 1000;
                            } else if (number >= 200 && number < 500) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 5 * 5) / 100;
                            } else if (number >= 500 && number < 1000) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 35 * 5) / 1000;
                            } else {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 3 * 5) / 100;
                            }
                        } else {
                            if (number >= 100 && number < 200) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 6 * 5) / 100;
                            } else if (number >= 200 && number < 500) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 4 * 5) / 100;
                            } else if (number >= 500 && number < 1000) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 3 * 5) / 100;
                            } else {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 3 * 5) / 100;
                            }
                        }
                    }
                }
                this.discount = this.priceMap.bossDiscountTotal;
            });
        },
        onDeclareAudit2() {
            this.$emit('onDeclareAudit2', this.declareDetail);
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        returnProductName() {
            let text = '';
            if (this.singleData.id) {
                this.singleData.productAttributeRelationBOList.forEach(
                    (item) => {
                        text = item.productName;
                    }
                );
            }
            return text;
        },
        returnText(type, orderType = '') {
            let text = '';
            if (this.singleData.id) {
                this.singleData.productAttributeRelationBOList.forEach(
                    (item) => {
                        if (item.attributeColumnName == type) {
                            item.productAttributeValueBOList.forEach((itm) => {
                                if (orderType) {
                                    if (
                                        itm.attributeValue ==
                                        this.singleData[orderType]
                                    ) {
                                        text = itm.attributeValueName;
                                    }
                                } else {
                                    if (
                                        itm.attributeValue ==
                                        this.singleData[type]
                                    ) {
                                        text = itm.attributeValueName;
                                    }
                                }
                            });
                        }
                    }
                );
            }
            return text;
        },
        onClose() {
            this.$router.go(-1);
        },
        onSubmit(num) {
            // if (this.declareDetail.contractId) {
            //     let res = await this.contracGet(this.declareDetail.contractId);
            //     if (res.data.status == 3) {
            //         this.$confirm('关联合同状态异常，是否确认审核？', '提示', {
            //             confirmButtonText: '确定',
            //             cancelButtonText: '取消',
            //             type: 'warning',
            //         })
            //             .then(() => {
            //                 let data = {
            //                     param: {
            //                         declareId: this.declareDetail.id,
            //                         ...this.auditData,
            //                     },
            //                 };
            //                 declareAudit(data).then((res) => {
            //                     if (res.code == 200 && res.data) {
            //                         this.$message.success('操作成功');
            //                         this.onClose();
            //                     }
            //                 });
            //             })
            //             .catch(() => {});
            // } else {
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            declareGet(data).then(async (res) => {
                if (res.code == 200) {
                    if (res.data.status == 9) {
                        this.$message.error('申请人已撤销当前申请');
                        this.onClose();
                        return;
                    } else {
                        let obj = {
                            param: {
                                declareId: this.declareDetail.id,
                                ...this.form,
                                status: num,
                                predictAmount:
                                    parseInt(this.estimateAmount.achievement) ||
                                    0,
                            },
                        };
                        console.log(obj);
                        declareAudit(obj).then((result) => {
                            if (result.code == 200 && result.data) {
                                this.$message.success('操作成功');
                                this.onClose();
                            }
                        });
                    }
                }
            });

            // }
        },
        onBlurRatio() {
            if (
                this.declareDetail.special == 1 &&
                !this.declareDetail.contractTargetBO &&
                !this.declareDetail.orderBO
            ) {
                return;
            }
            if (!Number(this.form.ratio) || this.form.ratio > 100) {
                this.form.ratio = '';
                return this.$message.error('请输入0-100之间的数字');
            }
            this.estimateAmount = this.$Calculator.calculator(
                this.discount,
                this.singleData.receivableAmount,
                this.declareDetail.amount,
                this.form.ratio
            );
            console.log(this.estimateAmount);
        },
        handleClose() {
            this.dialogDelete = false;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item {
    margin-bottom: 0px !important;
}
/deep/ .el-form-item__label {
    color: #666 !important;
    font-weight: normal !important;
}
.applyInvoice {
    width: 100%;
    height: 100%;

    .top_box {
        padding: 16px 0 0;
        height: calc(100% - 70px);
        overflow: hidden;
    }
    .main_box {
        padding-top: 27px;
        height: calc(100% - 55px);
        overflow: hidden;
        // display: flex;
        // justify-content: center;
        position: relative;
        // flex-direction: column;
        // padding-bottom: 175px;
        .fixed_top {
            overflow-y: auto;
            display: flex;
            // justify-content: center;
            flex-direction: column;
            align-items: center;
            height: 100%;
            box-sizing: border-box;
        }
        .fixed_bottom {
            position: absolute;
            bottom: 10px;
            left: 52%;
            transform: translateX(-50%);
            width: 385px;
            height: 150px;
            box-sizing: border-box;
            background: #fafafc;
            border-radius: 2px;
            padding: 10px 7px;
            margin: 0 auto;
        }
        .brand {
            color: #333333;
            font-weight: 600;
        }
        .other_toast {
            position: absolute;
            cursor: pointer;
            color: #2370eb;
            right: -250px;
            top: 0;
            user-select: none;
        }
        .other_info {
            width: 240px;
            padding: 14px 17px;
            box-sizing: border-box;
            background: #ffffff;
            border-radius: 10px;
            border: 1px dashed #2370eb;
            // p + p {
            //     margin-top: 16px;
            // }
            p {
                font-size: 14px;
                span:nth-child(1) {
                    display: inline-block;
                    width: 65px;
                    margin-right: 10px;
                    color: #666666;
                }
                span:nth-child(2) {
                    color: #333333;
                }
            }
        }
    }
    .top_title {
        font-size: 16px;
        color: #333333;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .icon-feiyongshenqing {
            color: #2370eb;
            margin-left: 19px;
            margin-right: 13px;
            font-weight: 600;
        }
    }
    .text_box {
        display: flex;
        padding-left: 52px;
        align-items: center;
        p {
            font-size: 14px;
            span:nth-child(1) {
                color: #666666;
                margin-right: 12px;
            }
            span:nth-child(2) {
                color: #333333;
                margin-right: 75px;
            }
        }
    }
    .invoice_box {
        margin-top: 12px;
        border-radius: 1px;
        flex: 1;
        background: #fff;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex: 1;
    }
    .bnt {
        background: #fff;
        padding: 14px 0;
        border-top: 1px solid #e5e5e5;
        text-align: center;
        button {
            padding: 5px 30px;
        }
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
.Delcontent {
    padding: 100px 80px;
    font-size: 16px;
}
.but-bottom {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
